.navbar {
    background-color: #f6f6f6;

    .cds--header__name--prefix {
        display: none;
    }

    .header-main {
        .icon-action-container {
            &.focused {
                background-color: #e0e0e0;
            }
        }
    }

    .drawer-container {
        .main-switcher {
            .single-item-drawer {
                /* !important - This is necessary to override default height. We want to customize the sidebar with items with more content instead of just a navigation link */
                height: 4em !important;

                .cds--switcher__item-link {
                    height: 4rem !important;
                }

                /* */

                .notification-view {
                    display: flex;
                    flex-flow: column wrap;
                    .main {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .status {
                            width: 50px;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            &.completed {
                                background-color: rgb(7, 170, 7);
                            }

                            &.pending {
                                background-color: yellow;
                            }

                            &.failed {
                                background-color: red;
                            }
                        }
                    }
                    .info {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: small;
                        font-weight: 200;
                    }
                }
            }
        }
    }
}

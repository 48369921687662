.filedropzone {
    &__form {
        margin-top: 10px;
        padding: 0 10px 10px 10px;

        .scriba-button {
            margin-top: 10px;
        }
    }

    &__file-details {
        margin-top: 1rem;
    }
}
.badge-ui {
    display: flex;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    justify-content: flex-end;

    .badge {
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #ff4a4a;
        border-radius: 50%;
    }
}

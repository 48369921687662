.card-wrapper {
    &.withBorder {
        &-1 {
            border: 1px solid black;
        }

        &-2 {
            border: 2px solid black;
        }
    }

    &.withTopMargin {
        &-10 {
            margin-top: 10px;
        }

        &-20 {
            margin-top: 20px;
        }
    }

    &.withInternalPadding {
        &-10 {
            padding: 10px;
        }
    }

    &.withShadow {
        box-shadow: 1px 1px 7px #b6bac0;
    }
}
.table-wrapper {
    .table-projects {
        .cds--data-table {
            .link-project {
                cursor: pointer;
            }
        }
    }
}

tr {
    &.selected {
        td {
            font-weight: bold;
            background-color: #fefefe;
        }
    }
}
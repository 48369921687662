.formJob-container {
    .info-header {
        margin-bottom: 10px;
    }
    .info-file-uploaded {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .download-source {
            display: flex;
            align-items: center;
            gap: 20px;

            .file-uploaded {
                font-size: 14px;
            }
        }
        .row {
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .download-icon {
        cursor: pointer;
    }

    .form-container {
        .spinner-container {
            display: flex;
            justify-content: center;
            height: 200px;
            align-items: center;
        }
    }

    .info-transcription-table {
        .cds--data-table-header {
            // Carbon does not provide right padding for table's title and desccription
            padding-right: 1rem;
        }

        .download-transcription {
            display: flex;
            justify-content: center;
            cursor: pointer;
        }

        .blob {
            background: black;
            border-radius: 50%;
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
            margin: 10px;
            height: 15px;
            width: 15px;
            transform: scale(1);
            animation: pulse-black 2s infinite;

            &.grey {
                background: grey;
                margin: 0 auto;
            }

            &.yellow {
                background: rgb(254, 190, 100);
                box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
                animation: pulse-yellow 2s infinite;
                margin: 0 auto;
            }

            &.red {
                background: red;
                margin: 0 auto;
            }
        }

        @keyframes pulse-yellow {
            0% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
            }

            70% {
                transform: scale(1);
                box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
            }

            100% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
            }
        }
    }
}
